// import React from 'react';
// import './about.css';
// // import  // Assuming you save your CSS in this file
// import sid from '../../assets/images/about-us/sid.jpg'
// // import profileImage from './path-to-your-image.png'; // Update the path as per your project structure

// const ProfileCard1 = () => {
//     return (
//         <div className="profile-container">
//         <h2>Menu</h2> {/* Add your heading here */}

//             <br></br>

//             <div className="profile-grid">
//                 {Array(8).fill().map((_, i) => (
//                     <div key={i} className="profile-card">
//                         <img src={sid} alt="John" className="profile-image" />
//                         <div className="profile-details">
//                             <h2 className="profile-name">Sanjay</h2>
//                             <p className="profile-role">Waiter</p>
//                             <p className="profile-description">5+ Experience in serving customers</p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default ProfileCard1;


import React from 'react';
import './about.css';

import one from '../../assets/images/FISH POKRA.jpg';
import two from '../../assets/images/Jamun.jpg';
import three from '../../assets/images/LAMB ROGAN JOSH.jpg';
import four from '../../assets/images/LAMB VINDALOO.jpg';
import five from '../../assets/images/Naan.jpg';
import six from '../../assets/images/bbq platter.jpg';
import seven from '../../assets/images/bbq.jpg';
import eight from '../../assets/images/chicken keema rice.jpg';
import nine from '../../assets/images/chop.jpg';
import ten from '../../assets/images/fish curry.jpg';
import eleven from '../../assets/images/jerra rice.jpg';
import twelve from '../../assets/images/lamb sagg.jpg';
import thirteen from '../../assets/images/okra masala.jpg';
import fourteen from '../../assets/images/salad.jpg';

import fifteen from '../../assets/images/pulao.jpg';
import sixteen from '../../assets/images/papadd.jpg';
import seventeen from '../../assets/images/papad.jpg';
import eighteen from '../../assets/images/okra masala.jpg';

import nineteen from '../../assets/images/masala papad.jpg';
import twenty from '../../assets/images/lamb sagg2.jpg';
import twentyone from '../../assets/images/chop.jpg';
// import fourteen from '../../assets/images/okra masala.jpg';



const ProfileCard1 = () => {
  const menuItems = [
    { src: one, name: "Fish Pokra", description: "Delicious crispy fried fish." },
    { src: two, name: "Jamun", description: "Sweet and tangy Indian dessert." },
    { src: three, name: "Lamb Rogan Josh", description: "Spicy and rich lamb curry." },
    { src: four, name: "Lamb Vindaloo", description: "Hot and tangy lamb curry." },
    { src: five, name: "Naan", description: "Soft and fluffy bread." },
    // { src: nine, name: "BBQ Platter", description: "Assorted grilled meats." },
    { src: seven, name: "BBQ", description: "Smoky and tender BBQ meats." },
    { src: eight, name: "Chicken Keema Rice", description: "Minced chicken with rice." },
    { src: nine, name: "Chop", description: "Grilled meat chops." },
    { src: ten, name: "Fish Curry", description: "Spicy and flavorful fish curry." },
    { src: eleven, name: "Jerra Rice", description: "Rice cooked with cumin seeds." },
    // { src: twelve, name: "Lamb Sagg", description: "Lamb cooked with spinach." },
    // { src: thirteen, name: "Okra Masala", description: "S/piced and sautéed okra." },
    // { src: fourteen, name: "Salad", description: "Fresh mixed vegetable salad." },
    { src: fifteen, name: "Pulao", description: "Pulao." },
    { src: sixteen, name: "Papad", description: "Papad" },
    { src: seventeen, name: "Papad", description: "Papad" },
    { src: eighteen, name: "Okra Masala", description: "Okra Masala" },
    { src: nineteen, name: "Masala Papad", description: "Masala Papad" },
    { src: twenty, name: "Lamb Sagg", description: "Lamb Sagg" },
    { src: twentyone, name: "Chop", description: "Chop" },
  ];

  return (
    <div className="profile-container">
      <h2>Menu</h2> {/* Add your heading here */}

      <br />

      <div className="profile-grid">
        {menuItems.map((item, index) => (
          <div key={index} className="profile-card">
            <img src={item.src} alt={item.name} className="profile-image" />
            <div className="profile-details">
              {/* <h2 className="profile-description">{item.name}</h2> */}
              <p className="profile-description">{item.name}</p>
              {/* <p className="profile-description">{item.description}</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileCard1;