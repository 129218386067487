import React from 'react';
import './about.css';
// import  // Assuming you save your CSS in this file
import sid from '../../assets/images/about-us/sid.jpg'
// import profileImage from './path-to-your-image.png'; // Update the path as per your project structure

const ProfileCard = () => {
    return (
        <div className="profile-container">
        <h2>Our Team</h2> {/* Add your heading here */}

            <br></br>
            <div className="profile-card">
                <img src={sid} alt="John" className="profile-image" />
                <div className="profile-details">
                    {/* <h2 className="profile-description">Narender</h2> */}
                    <p className="profile-role">Narender</p>
                    <p className="profile-description">Manager</p>
                </div>
            </div>
            <div className="profile-grid">
                {Array(4).fill().map((_, i) => (
                    <div key={i} className="profile-card">
                        <img src={sid} alt="John" className="profile-image" />
                        <div className="profile-details">
                            {/* <h2 className="profile-name">Sanjay</h2> */}
                            <p className="profile-role">Sanjay</p>
                            <p className="profile-description">Waiter</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProfileCard;
