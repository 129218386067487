import React, { useEffect } from "react";
import { motion } from "framer-motion";
//Components
import ScrollBtn from "../../helpers/ScrollBtn";
import AboutCollaboration from "./AboutCollaboration";
import AboutCareers from "./AboutCareers";
import LongmiandadaoAboutUs from "./LongmiandadaoAboutUs";
import AboutDedication from "./AboutDedication";
import AboutCustomers from "./AboutCustomers";
import ContactLanding from "../landing/ContactLanding";

const Longmiandadao = () => {
  useEffect(() => {
    document.title = "About | Al-Jannat Restaurants";
  }, []);
  return (
    <motion.main
      className="about"
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}>
      <LongmiandadaoAboutUs />
      <AboutDedication />
      <AboutCustomers />
      <AboutCareers />
      <AboutCollaboration />
      <ContactLanding />
      <ScrollBtn />
    </motion.main>
  );
}

export default Longmiandadao;







