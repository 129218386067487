import React from "react";
import { motion } from "framer-motion";
//Leaflet
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { MapContainer, TileLayer } from "react-leaflet";
import { Marker } from "react-leaflet";
import { Popup } from "react-leaflet";


let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;
// const position = [37.0902, -93.7129];

// const position = [32.06811444294815, 118.77883719385952];
const position = [32.103637102621356, 118.92607399650196];
// 32.09415, 118.90289
// 32.068005136329404, 118.77830522329796
// 32.068214452731354, 118.7782685655993
//xianlin
// 32.103637102621356, 118.92607399650196
//Gulou
// 32.071830571296246, 118.77680704368314



const XianlinMap = () => {
  const mapStyles = {
    width: '600px',
    height: '450px',
    border: '0'
  };

  return (
    <div className="contact-usss"><br></br>Contact Us
    <article className="section-10  flex-container flex-column">
      <a href="https://maps.app.goo.gl/7SiQnk2QZ6QhwYL37" target="_blank">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.839256871641!2d118.92607399650196!3d32.103637102621356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzLCsDA2JzEzLjEiTiAxMTjCsDU1JzMzLjciRQ!5e0!3m2!1sen!2sus!4v1692631189373!5m2!1sen!2sus"
        style={mapStyles}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />
    </a>

      {/* <motion.div
        className="map"
        initial={{ opacity: 0, translateX: -300 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: -300 }}
        transition={{ duration: 2 }}
      >
        <MapContainer
          id="map"
          center={position}
          zoom={9}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </motion.div> */}
      <motion.div
        className="contact-emails"
        initial={{ opacity: 0, translateX: 300 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 300 }}
        transition={{ duration: 2 }}
      >
      <h3>Service Hours</h3>
        {/* <p>Have a question, suggestion, or just want to say hello? We'd love to hear from you!</p> */}
        <section>
          {/* <h4>Customer Support:</h4>
          <p>Our dedicated customer support team is ready to assist you with any inquiries you may have.</p> */}
          <ul>
            <p> - Directions:
                Subway line 2,  Xuezelu station, Exit 1. Then follow the map
                </p>
                <br></br>
            {/* <li>- Email: khalilahmad4u@yahoo.com</li> */}
            <li>- Phone: +86 25 5223 5776</li>
            <li>- Hours: 11:00am - 10:00pm </li>
          </ul>
        </section>
        <br>
        </br>
        <br>
        </br>
        {/* <section>
          <h4>General Inquiries:</h4>
          <p>If you have general questions about our company, partnerships, or anything else, feel free to get in touch.</p>
          <ul>
            <li>- Email: khalilahmad4u@yahoo.com</li>
            <li>- Phone: +86 25 5223 5776</li>

          </ul>
        </section> */}
        <section>
          <h3>Feedback and Suggestions</h3>
          <p>We value your feedback and are always striving to improve. If you have any suggestions, comments, or ideas on how we can enhance our offerings, please let us know.</p>
          <br></br>
          <ul>
            <li>- Email: khalilahmad4u@yahoo.com</li>
            <li>- Phone: +86 25 5223 5776</li>

          </ul>
        </section>
      </motion.div>
    </article>
    </div>
  );
}


export default XianlinMap;



// AIzaSyBne3t4uC5qAAGuMUHF8J401W3eGbcHx_0
