import React, { useState } from 'react';
//images
// import SlideOne from '../../assets/images/img-one.jpeg'
// import SlideTwo from '../../assets/images/carbonara.jpeg'
// import SlideThree from '../../assets/images/img-three.jpeg'
// import SlideFour from '../../assets/images/img-four.jpeg'
// import SlideSix from '../../assets/images/LAMB ROGAN JOSH.jpg'


import ImgOne from "../../assets/images/about-us/2.jpg";
import ImgTwo from "../../assets/images/about-us/3.jpg";
// import ImgThree from "../../assets/images/about-us/chop.jpg";
import ImgThree from "../../assets/images/about-us/3.jpg";
import ImgFive from "../../assets/images/about-us/section-one.jpg";

// const XianlinAboutUs = () => {
//   return (
//     <article className="about-us">
//       <section className="content">
//         <h2>Xianlin</h2>
//         <h3 className='sub-title'>More than delicious food</h3>
//         <p>
//         Aljannat Indian Restaurant has been dedicated to bringing the rich, diverse flavors of traditional Indian, Pakistani, and Afghan cuisine. We pride ourselves on unique branding and a deep understanding of what our customers crave, transforming culinary desires into unforgettable taste experiences. Aljannat Indian Restaurant has got you covered. We value every customer because it is you who makes us special. Come experience the vibrant tastes and warm hospitality that have made us a cherished dining destination for over four decades.
//         </p>
//         <div className="section-one-glass"></div>
//       </section>
//       <section className="images">
//         <img src={ImgThree} alt="" aria-hidden="true" />
//         <img src={ImgFive} alt="" aria-hidden="true" />
//         <img src={SlideSix} alt="" aria-hidden="true" />
//         <img src={ImgTwo} alt="" aria-hidden="true" />
//       </section>
//     </article>
//   )
// }

// export default XianlinAboutUs;



const XianlinAboutUs = () => {
  // Array of images
  const images = [ImgOne, ImgThree, ImgFive, ImgTwo];
  
  // State to track the current image index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <article className="about-us">
      <section className="content">
        <h2>Xianlin</h2>
        <h3 className='sub-title'>More than delicious food</h3>
        <p>
          Aljannat Indian Restaurant has been dedicated to bringing the rich, diverse flavors of traditional Indian, Pakistani, and Afghan cuisine. We pride ourselves on unique branding and a deep understanding of what our customers crave, transforming culinary desires into unforgettable taste experiences. Aljannat Indian Restaurant has got you covered. We value every customer because it is you who makes us special. Come experience the vibrant tastes and warm hospitality that have made us a cherished dining destination for over four decades.
        </p>
        <div className="section-one-glass"></div>
      </section>
      <section className="images">
        <img src={images[currentIndex]} alt="Slider" />
        <button onClick={nextImage} className="next-button">➡️</button>
      </section>
    </article>
  );
};

export default XianlinAboutUs;
