import React from "react";

export default class MenuSliderCategories extends React.Component {
  render() {
    const { category, changeCategory } = this.props;
    return (
      <li>
        {/* <h1>hello</h1> */}
      //   <button onClick={() => changeCategory(category.name)}>
      //     {category.name}
      //   </button>
      </li>
    );
  }
}
