//375
import pizzaOne375 from '../assets/images/about-us/8.jpg'
import pizzaTwo375 from '../assets/images/about-us/21.jpg'
import pizzaThree375 from  '../assets/images/about-us/22.jpg'
import SalmonPoke375 from '../assets/images/about-us/23.jpg'
import VeggiePoke375 from '../assets/images/about-us/24.jpg'
import MiniSalmon375 from '../assets/images/about-us/25.jpg'
import ChickenAlfredo375 from '../assets/images/about-us/23.jpg'
import AllaGricia375 from '../assets/images/about-us/24.jpg'
import SheetPan375 from '../assets/images/about-us/25.jpg'
//700
import pizzaOne700 from '../assets/images/about-us/8.jpg'
import pizzaTwo700 from '../assets/images/about-us/21.jpg'
import pizzaThree700 from  '../assets/images/about-us/22.jpg'
import SalmonPoke700 from '../assets/images/about-us/23.jpg'
import VeggiePoke700 from '../assets/images/about-us/24.jpg'
import MiniSalmon700 from '../assets/images/about-us/25.jpg'
import ChickenAlfredo700 from '../assets/images/about-us/23.jpg'
import AllaGricia700 from '../assets/images/about-us/24.jpg'
import SheetPan700 from '../assets/images/about-us/25.jpg'


import imgOne375 from '../assets/images/about-us/20.jpg'
import imgTwo375 from '../assets/images/about-us/21.jpg'
import imgThree375 from '../assets/images/about-us/22.jpg'
import imgFour375 from '../assets/images/about-us/23.jpg'
import imgFive375 from '../assets/images/about-us/24.jpg'
import imgSix375 from '../assets/images/about-us/25.jpg'
const menuSliderCategories = [
    {
        name: 'Buffet',
        id: 'pizza',
    },
    {
        name: 'Menu',
        id: 'sushi',
    },
    // {
    //     name: 'pasta',
    //     id: 'pasta',
    // },
]
const menuSliderProducts = [
    {
        id: 'pepperoni-pizza',
        name: 'Buffet',
        // describtion:
            // 'Pizza crust yeast, pepperoni, tomato paste, mozzarella cheese, sugar.',
        // price: (85).toFixed(2),
        img375: pizzaOne375,
        img700: pizzaOne700,
        category: 'pizza',
    },
    {
        id: 'bbq-chicken-pizza',
        name: 'Zeera Rice',
        // describtion:
            // 'Rotisserie chicken, pizza dough, bbq sauce, smoked cheddar cheese, mozzarella cheese.',
        // price: (85).toFixed(2),
        img375: pizzaTwo375,
        img700: pizzaTwo700,
        category: 'pizza',
    },
    {
        id: 'cheese-pizza',
        name: 'Spicy Mutton',
        // describtion: 'Pizza dough, pizza sauce, provolone cheese, mozzarella.',
        // price: (85).toFixed(2),
        img375: pizzaThree375,
        img700: pizzaThree700,
        category: 'pizza',
    },
    {
        id: 'salmon-poke',
        img375: SalmonPoke375,
        img700: SalmonPoke700,
        name: 'Salmon Poke',
        // describtion:
            // 'Salmon, Avocado, Rice, Cabbage, Carrots, Creen onions, Sesame.',
        // price: (85).toFixed(2),
        category: 'sushi',
    },

    {
        id: 'veggie-poke',
        img375: VeggiePoke375,
        img700: VeggiePoke700,
        name: 'Veggie Poke',
        // describtion: 'Rice, Avocado, Cabage, Carrot, Ginger, Green Onion, Sesam.',
        // price: (8).toFixed(2),
        category: 'sushi',
    },
    {
        id: 'mini-salmon-set-22-pcs',
        img375: MiniSalmon375,
        img700: MiniSalmon700,
        name: 'Mini Salmon Set',
        // describtion:
            // 'Double Salmon Roll,Californication, Salmon Nigiri - 2Pcs, Eel Nigiri - 2Pcs,Vulcan Gunkan - 2Pcs',
        // price: (35).toFixed(2),
        category: 'sushi',
    },
    {
        id: 'chicken-alfredo',
        img375: ChickenAlfredo375,
        img700: ChickenAlfredo700,
        name: 'Chicken Alfredo',
        // describtion:
            // 'Skinless chicken breast, heavy cream, olive oil, parmigiano reggiano, black pepper.',
        // price: (2).toFixed(2),
        category: 'pasta',
    },
    {
        id: 'pasta-alla-gricia',
        img375: AllaGricia375,
        img700: AllaGricia700,
        name: 'Pasta Alla Gricia',
        // describtion:
            // 'Rigatoni pasta, pancetta, olive oil, pecorino romano, black pepper.',
        // price: (9).toFixed(2),
        category: 'pasta',
    },
    {
        id: 'sheet-pan-burrata-caprese-gnocchi',
        img375: SheetPan375,
        img700: SheetPan700,
        name: 'Burrata Caprese Gnocchi',
        // describtion:
            // 'Penne pasta, sour cream, rotisserie chicken, cherry tomatoes, curly kale.',
        // price: (5).toFixed(2),
        category: 'pasta',
    },
]

export { menuSliderProducts, menuSliderCategories };