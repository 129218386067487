// import React from 'react'
// //images
// // import SlideOne from '../../assets/images/img-one.jpeg'
// // import SlideTwo from '../../assets/images/carbonara.jpeg'
// // import SlideThree from '../../assets/images/img-three.jpeg'
// // import SlideFour from '../../assets/images/img-four.jpeg'

// // import SlideSix from '../../assets/images/LAMB ROGAN JOSH.jpg'


// // import ImgOne from "../../assets/images/about-us/FISH POKRA.jpg";
// // import ImgTwo from "../../assets/images/about-us/bbq.jpg";
// // import ImgThree from "../../assets/images/about-us/chop.jpg";


// import SlideSix from '../../assets/images/about-us/g4.jpg'
import sid from '../../assets/images/about-us/sid.jpg'

// import ImgOne from "../../assets/images/about-us/2.jpg";
// import ImgTwo from "../../assets/images/about-us/13.jpg";
// // import ImgThree from "../../assets/images/about-us/chop.jpg";
// import ImgThree from "../../assets/images/about-us/g1.jpg";
// import ImgFive from "../../assets/images/about-us/g2.jpg";

// const GulouStaff = () => {
//   return (
//     <article className="about-us">
//       <section className="content">
//         {/* <h2 className="sub-title">Happy Customers</h2> */}

//         <h2>Staff</h2>
//         <h3 className='sub-title'>introduction</h3>
//         <p>
//         <br></br>
//         </p>
//         <div className="section-one-glass"></div>
//       </section>
//       <section className="images">
//         <img src={sid} alt="" aria-hidden="true" />
//         <img src={sid} alt="" aria-hidden="true" />
//         <img src={sid} alt="" aria-hidden="true" />
//         <img src={sid} alt="" aria-hidden="true" />
//       </section>
//       <section className="images">
//         <h3>Narender</h3>
//         <h3>Sanjay</h3>
//         <h3>Parveen</h3>
//         <h3>XYZ</h3>
//       </section>
//       <section className="images">
//         <p>Manager</p>
//         <p>Chef</p>
//         <p>Chef</p>
//         <p>Waiter</p>
//       </section>


//       <section className="images">
//         <p>Specialize in Management</p>
//         <p>Specialize in Management</p>
//         <p>Specialize in Management</p>
//         <p>Specialize in Management</p>
//       </section>
//     </article>
//   )
// }

// export default GulouStaff;






// import React from 'react'
// //images
// import SlideOne from '../../assets/images/img-one.jpeg'
// import SlideTwo from '../../assets/images/carbonara.jpeg'
// import SlideThree from '../../assets/images/img-three.jpeg'
// import SlideFour from '../../assets/images/img-four.jpeg'


// const GulouStaff = () => {
//   return (
//     <article className="about-us">
//       <section className="content">
//         <h2>Our Team</h2>
//         <h3 className='sub-title'>The team behind our delicious food</h3>
//         <p>
//         Meet our dedicated team, the heart and soul of AL-Jannat Restaurants. Our staff is passionate about providing you with an unforgettable dining experience."
//         </p>
//         <div className="section-one-glass"></div>
//       </section>
//       {/* <section className="images">
//         <img src={SlideOne} alt="" aria-hidden="true" />
//         <img src={SlideTwo} alt="" aria-hidden="true" />
//         <img src={SlideThree} alt="" aria-hidden="true" />
//         <img src={SlideFour} alt="" aria-hidden="true" />
//       </section> */}
//      <section className="images-center">
//          <img src={sid} alt="" aria-hidden="true" />
//          {/* <img src={sid} alt="" aria-hidden="true" />
//          <img src={sid} alt="" aria-hidden="true" />
//          <img src={sid} alt="" aria-hidden="true" /> */}
//        </section>
//        <br></br>
// <br></br>
//      <section className="images">
//          <img src={sid} alt="" aria-hidden="true" />
//          <img src={sid} alt="" aria-hidden="true" />
//          <img src={sid} alt="" aria-hidden="true" />
//          <img src={sid} alt="" aria-hidden="true" />
//        </section>
//        <br></br>
//        <section className="images">
//          <h3>Narender</h3>
//          <h3>Sanjay</h3>
//          <h3>Parveen</h3>
//          <h3>XYZ</h3>
//        </section>
//        <br></br>
//        <section className="images">
//          <p>Manager</p>
//          <p>Chef</p>
//          <p>Chef</p>
//          <p>Waiter</p>
//        </section>
//     </article>
//   )
// }

// export default GulouStaff;











// import React from 'react'
//images
// import SlideOne from '../../assets/images/img-one.jpeg'
// import SlideTwo from '../../assets/images/carbonara.jpeg'
// import SlideThree from '../../assets/images/img-three.jpeg'
// import SlideFour from '../../assets/images/img-four.jpeg'

// import SlideSix from '../../assets/images/LAMB ROGAN JOSH.jpg'


// import ImgOne from "../../assets/images/about-us/FISH POKRA.jpg";
// import ImgTwo from "../../assets/images/about-us/bbq.jpg";
// import ImgThree from "../../assets/images/about-us/chop.jpg";


// import SlideSix from '../../assets/images/about-us/g4.jpg'


// import ImgOne from "../../assets/images/about-us/2.jpg";
// import ImgTwo from "../../assets/images/about-us/13.jpg";
// // import ImgThree from "../../assets/images/about-us/chop.jpg";
// import ImgThree from "../../assets/images/about-us/g1.jpg";
// import ImgFive from "../../assets/images/about-us/g2.jpg";

// const GulouStaff = () => {
//   return (
//     <article className="about-us">
//       <section className="content">
//         <h2>Gulou</h2>
//         <h3 className='sub-title'>More than delicious food</h3>
//         <p>
//         Aljannat Indian Restaurant has been dedicated to bringing the rich, diverse flavors of traditional Indian, Pakistani, and Afghan cuisine. We pride ourselves on unique branding and a deep understanding of what our customers crave, transforming culinary desires into unforgettable taste experiences. Aljannat Indian Restaurant has got you covered. We value every customer because it is you who makes us special. Come experience the vibrant tastes and warm hospitality that have made us a cherished dining destination for over four decades.
//         </p>
//         <div className="section-one-glass"></div>
//       </section>
//       <section className="images">
//         <img src={ImgThree} alt="" aria-hidden="true" />
//         <img src={ImgFive} alt="" aria-hidden="true" />
//         <img src={SlideSix} alt="" aria-hidden="true" />
//         <img src={ImgTwo} alt="" aria-hidden="true" />
//       </section>
//     </article>
//   )
// }

// export default GulouStaff;






// import React from "react";
//images
// import ImgOne from "../../assets/images/about-us/FISH POKRA.jpg";
// import ImgTwo from "../../assets/images/about-us/bbq.jpg";
// import ImgThree from "../../assets/images/about-us/chop.jpg";


// import ImgOne from '../../assets/images/about-us/6.jpg'
// import ImgTwo from '../../assets/images/about-us/7.jpg'
// import ImgThree from '../../assets/images/about-us/8.jpg'
// import SlideEight from '../../assets/images/about-us/9.jpg'



// const GulouStaff = () => {
//   return (
//     <article className="about-customer">

//       <section className="content">
//         <h2 className="sub-title">Happy Customers</h2>
//         <h3>Over 16000 positive feedback</h3>
//         <p>
//           We believe that our success is due to our commitment to providing high-quality food and exceptional service. Our menu features a wide range of delicious foods, all made with fresh, locally sourced ingredients. We take great care to ensure that each dish is prepared with the utmost attention to detail, so that every bite is as delicious as the last.
//           We also understand that great food is only part of the equation. That's why we place a strong emphasis on delivering exceptional service to every customer who walks through our doors. Our team of dedicated staff members is committed to providing a warm, welcoming atmosphere that makes every guest feel like they are part of the family.
//         </p>
//       </section>

//       <div className="images">
//         <img src={ImgOne} alt="" aria-hidden="true" />
//         <img src={ImgTwo} alt="" aria-hidden="true" />
//         <img src={ImgThree} alt="" aria-hidden="true" />
//         <img src={SlideEight} alt="" aria-hidden="true" />
//       </div>
//       <div className="images">
//         <h3>Narender</h3>
//         <h3>parveen</h3>
//         <h3>parveen</h3>
//         <h3>parveen</h3>
//       </div>
//     </article>
//   );
// }


// export default GulouStaff;






import React from 'react';
import ProfileCard from './ProfileCard';

function GulouStaff() {
    return (
        <div className="App">
          {/* <div className='our-team'>Our Team</div> */}
            <ProfileCard />
        </div>
    );
}

export default GulouStaff;
