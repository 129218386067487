
import ImgOne from '../assets/images/our-services/organic-food.webp'
import ImgTwo from '../assets/images/our-services/express-delivery.webp'
import ImgThree from '../assets/images/our-services/unforgable-taste.webp'
// import ImgFour from '../assets/images/our-services/gulou.jpg'
// import ImgFive from '../assets/images/our-services/xianlin.jpg'
import ImgSix from '../assets/images/our-services/longmian.jpg'


const ourServices = [
    {
        id: 1,
        img: ImgSix,
        name: 'Gulou',
        description:
            'Pure and healthy organic food is our lifestyle. The products we consume has impact on out future and we do everything to keep the future healthy',
        link: 'gulou'
    },
    {
        id: 2,
        img: ImgSix,
        name: 'Xianlin',
        description:
            'Choose from a variety of express delivery services depending on your needs. Whether in a hurry to eat or have some plans tomorrow, we have got you covered',
        link: 'xianlin'
    },
    {
        id: 3,
        img: ImgSix,
        name: 'Longmiandadao',
        description:
            'Our goal is to provide our customers with excellent service, great taste and unforgettable experiences. This will be a mind-blowing experience for your taste buds',
        link: 'longmiandadao'
    },
]

export default ourServices;