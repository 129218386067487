import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";

import img375 from '../../assets/images/about-us/g1.webp'
import img700 from '../../assets/images/about-us/g1.webp'
import img1440 from '../../assets/images/about-us/g1.webp'
// import PizzaOne from '../../assets/images/welcome-section/pizza-one-parallax.webp'
import PizzaOne from '../../assets/images/about-us/g1.webp'

import PizzaTwo from '../../assets/images/about-us/14.jpg'
import PizzaThree from '../../assets/images/about-us/g3.jpg'
import PizzaFour from '../../assets/images/welcome-section/sa.jpg'


import { motion } from "framer-motion";

const foodSection = () => {
  return (
    <article className="food-section" >
      <section className="section-2-info flex-container flex-column txt-center pop-font">
        {/* <motion.img
          src={PizzaTwo} alt="" className=" pizza-two"
          initial={{ opacity: 0, translateX: -200 }}
          whileInView={{
            opacity: 1, translateX: -100
          }}
          transition={{ duration: 5 }}
        />
        <motion.img
          src={PizzaThree} alt="" className=" pizza-one"
          initial={{ opacity: 0, translateX: 200 }}
          whileInView={{
            opacity: 1, translateX: 100
          }}
          transition={{ duration: 5 }}
        /> */}
        <h2 className="txt-white">
          food to <span> Al-Jannat </span> Restaurant
        </h2>
        <p>
        

        Aljannat Indian Restaurant has been dedicated to bringing the rich, diverse flavors of traditional Indian, Pakistani, and Afghan cuisine. We pride ourselves on unique branding and a deep understanding of what our customers crave, transforming culinary desires into unforgettable taste experiences.


         Aljannat Indian Restaurant has got you covered. We value every customer because it is you who makes us special. Come experience the vibrant tastes and warm hospitality that have made us a cherished dining destination for over four decades.
        </p>
      </section>
      {/* <LazyLoadImage
        className="section-two-img"
        src={PizzaOne}
        // srcSet={`${img1440} 1440w, ${img700} 700w, ${img375} 375w`}
        sizes="(min-width: 1440px) 1440px, (min-width: 700px) 700px, 375px"
        alt="Al-Jannat Restaurants restaurant interior with people at the tabel and the staff serving the customers"
      /> */}
    </article>
  )
}

export default foodSection;