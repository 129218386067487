import React from 'react'
import { motion } from "framer-motion";
// import PizzaOne from '../../assets/images/contact-us/image-one-parallax.webp'
import PizzaThree from '../../assets/images/contact-us/pulao.jpg'
import PizzaFour from '../../assets/images/contact-us/sa.jpg'
import Pizzatwo from '../../assets/images/contact-us/sa.jpg'



const ContactUsLanding = () => {
  return (
    <section className="contact-us-landing flex-container flex-row txt-white" >
      {/* <motion.img
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 500, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
        src={PizzaThree} alt="" aria-hidden="true" className="parallax company-details-image"
      />  */}
      {/* <motion.img
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 600, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
        src={Pizzatwo} alt="" aria-hidden="true" className="parallax company-details-image-two"
      />
      <motion.img
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 900, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
        src={PizzaFour} alt="" aria-hidden="true" className="parallax company-details-image-three"
      /> */}
      {/* <section className='company-details'>
        <div>
          <h2>+86 25 5223 5776</h2>
          <p>Contact us if you have any questions</p>
        </div>
        <div>
          <h2>Gulou </h2>
          <p>Nanjing, China</p>
        </div>
        <div>
          <h2>Time</h2>
          <p>10:00am - 10:00pm</p>
        </div>
      </section>
      <section className='company-details'>
        <div>
          <h2>+86 25 5223 5776</h2>
          <p>Contact us if you have any questions</p>
        </div>
        <div>
          <h2>Gulou </h2>
          <p>Nanjing, China</p>
        </div>
        <div>
          <h2>Time</h2>
          <p>10:00am - 10:00pm</p>
        </div>
      </section>
      <section className='company-details'>
        <div>
          <h2>+86 25 5223 5776</h2>
          <p>Contact us if you have any questions</p>
        </div>
        <div>
          <h2>Gulou </h2>
          <p>Nanjing, China</p>
        </div>
        <div>
          <h2>Time</h2>
          <p>10:00am - 10:00pm</p>
        </div>
      </section> */}
    </section>
  );

}


export default ContactUsLanding;