import React, { useEffect } from "react";
import { motion } from "framer-motion";
//Components
import ScrollBtn from "../../helpers/ScrollBtn";
import AboutCollaboration from "./AboutCollaboration";
import AboutCareers from "./AboutCareers";
// import XianlinAboutUs from "./XianlinAboutUs";
// import XianlinAboutUs from "./XianlinAboutUs";
// import XianlinAboutUs from "./XianlinAboutUsianlinAboutUs";
import XianlinAboutUs from "./XianlinAboutUs"
import AboutDedication from "./AboutDedication";
import AboutCustomers from "./AboutCustomers";
import ContactLanding from "../landing/ContactLanding";
import XianlinMap from "./XianlinMap";
import XianlinStaff from "./XianlinStaff";
// import XianlinMap from "./XianlinMap";
// 
const Xianlin = () => {
  useEffect(() => {
    document.title = "About | Al-Jannat Restaurants";
  }, []);
  return (
    <motion.main
      className="about"
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}>
      {/* <XianlinMap /> */}
      <XianlinAboutUs />
      <br>
      </br>
      <br></br>
      {/* <AboutDedication /> */}
      <AboutCustomers />
      <br></br>
      <br></br>

      <XianlinStaff></XianlinStaff>
      <br></br>
      <br></br>
      <br></br>
      {/* <AboutCareers /> */}
      {/* <AboutCollaboration /> */}
      <XianlinMap />
      <ScrollBtn />
    </motion.main>
  );
}

export default Xianlin;







