import React from "react";
import Github from "../../assets/images/socials/github.svg";
// import Linkedin from "../../assets/images/socials/linkedin.svg";
import facebook from "../../assets/images/socials/facebook.svg";
import instagram from "../../assets/images/socials/instagram.svg"

const socials = [
  {
    id: 1,
    href: "https://github.com/sadaqatem",
    img: Github,
    name: Github
  },
  {
    id: 2,
    href: "https://www.instagram.com/al_jannat_indian_restaurant/",
    img: instagram,
    name: instagram
  },
  {
    id: 3,
    href: "https://www.facebook.com/ALJannatNanjing/",
    img: facebook,
    name: facebook
  }
];

export default class FooterSocials extends React.Component {
  render() {
    return (
      <ul className="socials">
      {socials.map(social =>
      <li key={social.id}>
          <a
            href={social.href}
            target="_blank"
            rel="noreferrer"
            aria-label={social.name}
          >
          <span aria-hidden="true">{social.name}</span>
            <img src={social.img} alt="" aria-hidden="true" />
          </a>
        </li>)}
      </ul>
    );
  }
}
