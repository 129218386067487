//375px

import imgOne375 from '../assets/images/about-us/19.jpg'
import imgTwo375 from '../assets/images/about-us/18.jpg'
import imgThree375 from '../assets/images/about-us/17.jpg'
import imgFour375 from '../assets/images/about-us/24.jpg'
import imgFive375 from '../assets/images/about-us/4.jpg'
import imgSix375 from '../assets/images/about-us/26.jpg'

import imgOne700 from '../assets/images/about-us/23.jpg'
import imgTwo700 from '../assets/images/about-us/22.jpg'
import imgThree700 from '../assets/images/about-us/21.jpg'
import imgFour700 from '../assets/images/about-us/17.jpg'
import imgFive700 from '../assets/images/about-us/6.jpg'
import imgSix700 from '../assets/images/about-us/26.jpg'
//1440
import imgOne1440 from '../assets/images/about-us/21.jpg'
import imgTwo1440 from '../assets/images/about-us/22.jpg'
import imgThree1440  from '../assets/images/about-us/23.jpg'
import imgFour1440 from '../assets/images/about-us/24.jpg'
import imgFive1440 from '../assets/images/about-us/25.jpg'
import imgSix1440 from '../assets/images/about-us/26.jpg'

const pizzaMenuPreview = [
    {
        id: 1,
        img375: imgOne375,
        img700: imgOne700,
        img1440: imgOne1440,
        name: 'Sagg',
//        price: 5.0,
        // currency: '$',
        description:
            'A tangy and hearty Indian dish featuring leafy greens like spinach simmered with spices, butter, and cream for a comforting, flavorful experience.',
    },
    {
        id: 2,
        img375: imgTwo375,
        img700: imgTwo700,
        img1440: imgTwo1440,
        name: 'Spicy Mutton',
        // price: 9.0,
//        currency: '$',
        description:
            'Aromatic Indian mutton dish slow-cooked with a blend of bold spices, tender meat, and rich flavors.',
    },
    {
        id: 3,
        img375: imgThree375,
        img700: imgThree700,
        img1440: imgThree1440,
        name: 'Zeera Rice',
//        price: 5.0,
//        currency: '$',
        description:
            'A fragrant Indian rice dish infused with the nutty aroma of cumin seeds, cooked to fluffy perfection with subtle spices.',
    },
    {
        id: 4,
        img375: imgFour375,
        img700: imgFour700,
        img1440: imgFour1440,
        name: 'Biryani',
        // price: 10.0,
//        currency: '$',
        description:
            'A rice dish where marinated meat cooked with fragrant basmati rice, saffron, and a medley of spices, creating a rich and flavorful feast.',
    },
    {
        id: 5,
        img375: imgFive375,
        img700: imgFive700,
        img1440: imgFive1440,
        name: 'Gajjar ka Halwa',
        // price: 8.0,
//        currency: '$',
        description:
            'A rich and indulgent Indian dessert made from grated carrots slow-cooked in ghee, milk, and sugar, then garnished with aromatic spices, nuts, and dried fruits.',
    },
    {
        id: 6,
        img375: imgSix375,
        img700: imgSix700,
        img1440: imgSix1440,
        name: 'Papad',
        // price: 10.0,
//        currency: '$',
        description:
            'A thin, crispy Indian wafer made from seasoned lentil or chickpea flour, typically roasted or fried, and enjoyed as a crunchy side or snack.',
    },
]

export default pizzaMenuPreview;