export const AllCategories = [
  {
    name: "Menu",
    id: "Menu",
  },
  // {
  //   name: "Popular",
  //   id: "Popular",
  // },
  // {
  //   name: "Deserts",
  //   id: "Pasta",
  // },
  // {
  //   name: "Juices",
  //   id: "Juices",
  // },
  // {
  //   name: "Drinks",
  //   id: "Drinks",
  // },
  // {
  //   name: "Sale",
  //   id: "Sale",
  // },
];
